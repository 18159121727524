<template>
  <div>
    <heading-separator :title="title" :div="div"></heading-separator>
  </div>
</template>

<script>
import HeadingSeparator from './HeadingSeparator.vue';

export default {
  name: "ConsultingHeading",
  components: { HeadingSeparator },
  data() {
    return {
      title: 'HVS Consulting',
      div: "<div> <p class='white--text'> Hedge Vision Systems' tech consulting arm is dedicated to driving digital transformation and cloud adaptability in the financial industry, with a strong commitment to data security and governance. We specialize in developing customized products and apps using the latest technologies, specifically tailored to modernize legacy systems and enable efficient cloud hosting. </p> <p class='white--text'> Our focus on scalability and creating a centralized source of truth is achieved through the implementation of API-first architectures, ensuring optimal app performance. Leveraging our expertise in data warehousing and business intelligence, we provide in-depth analysis and actionable insights for real-time decision-making. Moreover, our proficiency in business analysis and process standardization allows us to identify challenges and propose streamlined tech solutions to optimize operations.</p><p class='white--text'>We have a niche in the financial domain with a specialization Alternate Asset Management including public & private credit with strategies such as Direct lending, CLOs, Mezzanine Financing, BDC, etc.</p></div>"
    };
  },
};
</script>
